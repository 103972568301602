import React from "react";

import InternalPageLayout from "../components/internal-page-layout";
import InternalPageContentCard from "../components/internal-page-content-card";
import TestimonialCard from "../components/testimonial-card";
import smartHomeImage from "../images/smarthome/smarthome-6.jpg";
import lutronProLogo from "../images/lutron-pro-logo.png";
import Gallery from "../components/gallery";
import SEO from "../components/seo";

function SmartHome() {
  return (
    <InternalPageLayout title="Smart Home Installation" crumbs={["Services"]}>
      <SEO
        title="Smart Home Installation in Baltimore County" />
      <InternalPageContentCard>
        <div className="w-full md:w-2/5 lg:w-1/2 mb-6 md:ml-6 md:float-right">
          <img src={smartHomeImage} className="max-w-full" alt="smart home installation services" />
        </div>
        <p className="mb-3">As the popularity and the capabilities of Smart Home control grows so do the benefits and convenience they provide. We represent Lutron Electronics who is the leader in home lighting control. With a Lutron system installed, you have the ability to dim lighting to preset levels with the push of a button. From a local keypad in your foyer or a smart phone across the world, you can control the lighting in your home.</p>
        <p className="mb-3">Lutron allows you to have customizable lighting schemes and schedules, temperature control, and power shading.</p>
        <p className="mb-6">Contact us if you’re interested in learning more!</p>

        <p className="font-bold">Resources:</p>

        <ul className="mt-2 list-disc ml-8">
          <li><a className="text-maroon hover:underline" href="https://www.casetawireless.com/" target="_blank">Caseta Wireless</a></li>
          <li><a className="text-maroon hover:underline" href="http://www.lutron.com/TechnicalDocumentLibrary/3672737_RA2_Select_Brochure.pdf" target="_blank">Ra2 Select</a></li>
          <li><a className="text-maroon hover:underline" href="http://www.lutron.com/technicaldocumentlibrary/367-1663.pdf" target="_blank">Radio Ra2</a></li>
        </ul>

        <div className="bg-white border-t border-gray-200 p-4 md:p-6 lg:p-12 rounded-sm -mb-12 -ml-4 -mr-4 mt-12 md:-ml-6 md:-mr-6 lg:-ml-12 lg:-mr-12">
          <div className="w-full lg:w-3/4 md:flex space-between items-center mx-auto">
            <div className="w-full md:w-2/3 md:pr-12">
              <h5 className="font-semibold text-lg text-gray-800">Experienced Lutron Professionals</h5>
              <p className="text-gray-700">With years of experience installing Lutron smart home systems, we can help you choose the right products and install them correctly to help you automate your home.</p>
            </div>
            <div className="w-full md:w-1/3 md:mt-0 mt-4">
              <img src={lutronProLogo} alt="lutro pro residential contractor" className="w-full h-auto" />
            </div>
          </div>
        </div>
      </InternalPageContentCard>


      <InternalPageContentCard>
        <h3 className="text-gray-800 mb-1 font-semibold text-lg">Popular smart home products:</h3>
        <p className="mb-12">Here are some of our more popular smart home products.</p>
        <Gallery gallery="smarthome" />
      </InternalPageContentCard>

      <div className="lg:flex">
        <div className="w-full mb-4 lg:w-1/2 lg:pr-4">
          <TestimonialCard
            author="Review on AngiesList"
            review="They were very pleasant to have in my house. Exceptional work from start to finish, very clean and knowledgeable of products and work. Best experience by far would highly recommend to anyone needing electrical work done."
          />
        </div>
        <div className="w-full mb-4 lg:w-1/2 lg:pl-4">
          <TestimonialCard
            author="Review on AngiesList"
            review="I'd give them an A+, that's why I use them. They're completely clean and leave things better than they found it. The price is good. They're efficient, quick, and very clean and tidy. They're very respectful of the home they're working in."
          />
        </div>
      </div>
    </InternalPageLayout>
  );
}

export default SmartHome;
