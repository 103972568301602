import React from "react";

const TestimonialCard = ({author, review}) => (
  <a href="https://www.angieslist.com/companylist/us/md/jarrettsville/wyatt-electric-inc-reviews-9046610.htm" target="_blank">
    <div className="bg-gray-300 hover:bg-gray-400 rounded w-full mb-12 text-center">
      <div className="p-4 md:p-6 lg:p-12 text-gray-800 relative">
        <p className="text-maroon text-center">
          <svg className="inline" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M6 17h3l2-4V7H5v6h3zm8 0h3l2-4V7h-6v6h3z" /><path d="M0 0h24v24H0z" fill="none" /></svg>
        </p>
        <p className="text-black mt-8">{review}</p>
        <h3 className="uppercase text-gray-600 mt-5">{author}</h3>
      </div>
    </div>
  </a>
)

export default TestimonialCard;